import { Fernet } from 'fernet-ts';

const pbkdf2 = require('pbkdf2');
const Cookies = require("js-cookie");

const salt = 'e1a7a49b5bad75ec81fcb8cded4bbc0c';
const iterations = 480000;
const keyLength = 32;
const digest = 'sha256';

let cachedKey: string | undefined;
let fernet: Fernet | undefined;

export let useEncryption = false;

export async function initLocalEncryptionCache(): Promise<boolean> {
    const browserCachedKey = Cookies.get('fw-ucachedkey');
    if(browserCachedKey) {
        cachedKey = browserCachedKey;
        fernet = await Fernet.getInstance(browserCachedKey);
        useEncryption = true;
    }
    else {
        cachedKey = undefined;
        fernet = undefined;
        useEncryption = false;
    }

    return useEncryption;
} 

export function generateCachedKey(pwd: string, callback: Function): void {
    let utf8Encode = new TextEncoder();
    let encodedPwd = utf8Encode.encode(pwd);
    let encodedSalt = utf8Encode.encode(salt);

    pbkdf2.pbkdf2(encodedPwd, encodedSalt, iterations, keyLength, digest, callback);
}

export function getKey(): string {
    return Cookies.get('fw-ukey');
}

export async function encrypt(message: string): Promise<string> {
    if(!fernet) throw new Error('Missing encryption configuration.');

    const token = await fernet.encrypt(message);

    return '+' + token;
}

export async function decrypt(token: string): Promise<string> {
    if(!fernet) return token;

    try {
        // token is received with the "+" prefix
        const slicedToken = token.slice(1);
        const plainText = await fernet.decrypt(slicedToken);
        return plainText;
    }
    catch {
        // unable to decrypt, likely due to wrong key
        return token;
    }   
}